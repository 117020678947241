<template>
    <div>
        <BasePageBreadCrumb :pages="pages" title="config. da conta" :menu="menu"/>

        <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                                <b-tabs active-nav-item-class="active-tab-qsuite">
                                    <form class="px-2 needs-validation" novalidate @submit.prevent="save">

                                        <b-tab class="tab-pane show active" id="dadosdoimovel">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-building font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Portal do cliente</span>
                                                </div>
                                            </template>

                                            <div class="form-body mt-5">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <h4 class="card-title">Acesso ao portal do cliente</h4>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Frase de impacto </label>
                                                            <textarea class="form-control mb-2" rows="3" placeholder="Segurimos utilizar até 160 caracteres" v-model="client.sentence"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3">
                                                        <label>Personalize a aparência do seu portal</label>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>BACKGROUND LOGIN</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="client.desktop_background" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>BACKGROUND LOGIN</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="client.mobile_background" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3"></div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Cor componentes (ESCURO)</label>
                                                            <input type="color" class="form-control" value="#563d7c" v-model="client.component_color">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Cor background (ESCURO)</label>
                                                            <input type="color" class="form-control" value="#563d7c" v-model="client.background_color">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Ativar função novo cadastro </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.active_register">
                                                                <option value="1">Ativo</option>
                                                                <option value="2">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o imóvel na plataforma</small>

                                                    </div>
                                                    
                                                    <form action="" class="px-2 needs-validation" novalidate="">
                                                        <div class="form-body">
                                                            <div class="row">
                                                              
                                                           
                                                                <div class="col-12 pt-3">
                                                                    <hr>
                                                                    <label class="font-14">Personalize a aparência do seu portal - interno</label>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label>Frase de impacto </label>
                                                                        <textarea class="form-control mb-2" rows="3" v-model="client.sentence_logged" placeholder="Segurimos utilizar até 160 caracteres"></textarea>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                                    <label>BANNER LOGIN - DESKTOP</label>
                                                                    <div class="input-group mb-3">
                                                                        <BaseImageUpload v-model="client.desktop_background_logged" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                                    <label>BANNER LOGIN - MOBILE</label>
                                                                    <div class="input-group mb-3">
                                                                        <BaseImageUpload v-model="client.mobile_background_logged" />
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                       
                                                    </form>

                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab class="tab-pane show active" id="portaldocorretor">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Portal do corretor</span>
                                                </div>
                                            </template>
                                            <div class="form-body mt-5">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <h4 class="card-title">Acesso ao portal do corretor</h4>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Frase de impacto </label>
                                                            <textarea class="form-control mb-2" v-model="broker.sentence"  rows="3" placeholder="Segurimos utilizar até 160 caracteres"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3">
                                                        <label>Personalize a aparência do seu portal</label>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>BACKGROUND LOGIN</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="broker.desktop_background" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>BACKGROUND LOGIN</label>
                                                        <div class="input-group mb-3">
                                                           <BaseImageUpload v-model="broker.mobile_background" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3"></div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Cor componentes (ESCURO)</label>
                                                            <input type="color" v-model="broker.component_color" class="form-control" value="#563d7c">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Cor background (ESCURO)</label>
                                                            <input type="color" v-model="broker.background_color" class="form-control" value="#563d7c">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Ativar função novo cadastro </label>
                                                            <select v-model="broker.active_register" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o imóvel na plataforma</small>
                                                    </div>
                                                </div>


                                                <form action="" class="px-2 needs-validation" novalidate="">
                                                        <div class="form-body">
                                                            <div class="row">
                                                              
                                                           
                                                                <div class="col-12 pt-3">
                                                                    <hr>
                                                                    <label class="font-14">Personalize a aparência do seu portal - interno</label>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label>Frase de impacto </label>
                                                                        <textarea class="form-control mb-2" rows="3" v-model="broker.sentence_logged" placeholder="Segurimos utilizar até 160 caracteres"></textarea>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                                    <label>BANNER LOGIN - DESKTOP</label>
                                                                    <div class="input-group mb-3">
                                                                        <BaseImageUpload v-model="broker.desktop_background_logged" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                                    <label>BANNER LOGIN - MOBILE</label>
                                                                    <div class="input-group mb-3">
                                                                        <BaseImageUpload v-model="broker.mobile_background_logged" />
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                       
                                                    </form>


                                            </div>
                                        </b-tab>

                                        <b-tab class="tab-pane show active" id="tecnico">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Acesso do técnico</span>
                                                </div>
                                            </template>
                                            <div class="form-body mt-5">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <h4 class="card-title">Acesso do técnico</h4>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Frase de impacto </label>
                                                            <textarea v-model="technical.sentence" class="form-control mb-2" rows="3" placeholder="Segurimos utilizar até 160 caracteres"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3">
                                                        <label>Personalize a aparência do seu portal</label>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>BACKGROUND LOGIN</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="technical.desktop_background" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>BACKGROUND LOGIN</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="technical.mobile_background" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3"></div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Cor componentes (ESCURO)</label>
                                                            <input type="color" class="form-control" value="#563d7c">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Cor background (ESCURO)</label>
                                                            <input type="color" class="form-control" value="#563d7c">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 pt-3">
                                                        <div class="form-group">
                                                            <label>Ativar função novo cadastro </label>
                                                            <select v-model="technical.active_register" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                                <option value="1">Ativo</option>
                                                                <option value="2">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o imóvel na plataforma</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab class="tab-pane show active" id="configuracoes">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Configurações gerais <b-badge variant="danger" v-show="settings_error">1</b-badge></span>
                                                </div>
                                            </template>
                                            <div class="form-body mt-5">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Domínio portal do cliente </label>
                                                            <input type="text" class="form-control" v-model="settings.domain" placeholder="ex: https://www.qsuite.com.br" v-validate="'required'" name="domain" :class="{'is-invalid': errors.has('domain')}">
                                                        </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Domínio portal do corretor </label>
                                                            <input type="text" class="form-control" v-model="settings.domain_broker" placeholder="ex: https://www.qsuite.com.br" v-validate="'required'" name="domain" :class="{'is-invalid': errors.has('domain')}">
                                                        </div>
                                                    </div>
            
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Nome de exibição </label>
                                                            <input type="text" class="form-control" data-toggle="tooltip" data-placement="bottom" v-model="settings.display_name" title="Este nome será exibido como título da página." placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3">
                                                        <label>Personalize a aparência</label>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6 pt-3">
                                                        <label>LOGO COLORIDA</label>
                                                        <div class="input-group mb-3">
                                                             <BaseImageUpload v-model="settings.logo" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3"></div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <label>LOGO BRANCA</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="settings.logo_white" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3"></div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <label>LOGO PRETA</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="settings.logo_black" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pt-3"></div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <label>ICO</label>
                                                        <div class="input-group mb-3">
                                                            <BaseImageUpload v-model="settings.ico" />
                                                        </div>
                                                        <p class="font-11">Formato aceito: .png -  Dimensão recomendada: 85x85</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                                <button type="submit" class="btn btn-info ml-2">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</div>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import ThemeService from "@/services/resources/ThemeService";

const service = ThemeService.build();
export default {
    components:{
        VueUploadMultipleImage
    },
    data(){
        return{
            client:{
                sentence_logged: null,
                sentence: null,
                desktop_background: [],
                mobile_background: [],
                desktop_background_logged: [],
                mobile_background_logged: [],
                component_color: null,
                background_color: null,
                active_register: 1,
                active_enterprise: 1
            },
            broker:{
                sentence: null,
                desktop_background: [],
                mobile_background: [],
                component_color: null,
                background_color: null,
                active_register: 1,
                desktop_background_logged: [],
                sentence_logged: null,
                mobile_background_logged: []
            },
            technical:{
                sentence: null,
                desktop_background: [],
                mobile_background: [],
                component_color: null,
                background_color: null,
                active_register: 1
            },
            settings:{
                domain: null,
                domain_broker: null,
                display_name: null,
                logo: [],
                logo_white: [],
                logo_black: [],
                ico: []
            },
            settings_error: false,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'config. da conta',
                    to: 'SettingsTheme'
                }
            ],
            menu:[],
        }
    },
    methods:{
        uploadDesktopClient(formData, index, fileList) {
            this.client.desktop_background = fileList;
        },
        uploadMobileClient(formData, index, fileList) {
            this.client.mobile_background = fileList;
        },
        uploadDesktopBroker(formData, index, fileList) {
            this.broker.desktop_background = fileList;
        },
        uploadMobileBroker(formData, index, fileList) {
            this.broker.mobile_background = fileList;
        },
        uploadDesktopTechnical(formData, index, fileList) {
            this.technical.desktop_background = fileList;
        },
        uploadMobileTechnical(formData, index, fileList) {
            this.technical.mobile_background = fileList;
        },
        uploadLogo(formData, index, fileList){
            this.settings.logo = fileList;
        },
        uploadLogoWhite(formData, index, fileList){
            this.settings.logo_white = fileList;
        },
        uploadLogoBlack(formData, index, fileList){
            this.settings.logo_black = fileList;
        },
        uploadIco(formData, index, fileList){
            this.settings.ico = fileList;
        },
        save(){
            if(!this.settings.domain){
                this.settings_error = true;
                this.$bvToast.toast('Você precisa colocar o domínio!', {
                    title: 'Configurações',
                    autoHideDelay: 5000,
                    type: 'danger'
                })
                return;
            }
            this.$validator.validateAll().then((result) => {
                if (result) {

                    let data = {
                        id: 0,
                        theme: [
                        {
                            key: 'PORTAL_CLIENT_SENTENCE',
                            value: this.client.sentence
                        },
                        {
                            key: 'PORTAL_CLIENT_COMPONENT_COLOR',
                            value: this.client.component_color
                        },
                        {
                            key: 'PORTAL_CLIENT_BACKGROUND_COLOR',
                            value: this.client.background_color
                        },
                        {
                            key: 'PORTAL_CLIENT_ACTIVE_REGISTER',
                            value: this.client.active_register
                        },
                        {
                            key: 'PORTAL_CLIENT_ACTIVE_ENTERPRISE',
                            value: this.client.active_enterprise
                        },
                        {
                            key: 'PORTAL_CLIENT_BACKGROUND_DESKTOP',
                            value: this.client.desktop_background[0],
                            image:  true
                        },
                        {
                            key: 'PORTAL_CLIENT_BACKGROUND_MOBILE',
                            value: this.client.mobile_background[0],
                            image: true
                        },
                        {
                            key: 'PORTAL_CLIENT_BACKGROUND_DESKTOP_LOGGED',
                            value: this.client.desktop_background_logged[0],
                            image:  true
                        },
                        {
                            key: 'PORTAL_CLIENT_BACKGROUND_MOBILE_LOGGED',
                            value: this.client.mobile_background_logged[0],
                            image: true
                        },
                        {
                            key: 'PORTAL_CLIENT_SENTENCE_LOGGED',
                            value: this.client.sentence_logged,
                            image: true
                        },
                        // Broker
                        {
                            key: 'PORTAL_BROKER_SENTENCE',
                            value: this.broker.sentence
                        },
                        {
                            key: 'PORTAL_BROKER_COMPONENT_COLOR',
                            value: this.broker.component_color
                        },
                        {
                            key: 'PORTAL_BROKER_BACKGROUND_COLOR',
                            value: this.broker.background_color
                        },
                        {
                            key: 'PORTAL_BROKER_ACTIVE_REGISTER',
                            value: this.broker.active_register
                        },
                        {
                            key: 'PORTAL_BROKER_BACKGROUND_DESKTOP',
                            value: this.broker.desktop_background[0],
                            image:  true
                        },
                        {
                            key: 'PORTAL_BROKER_BACKGROUND_MOBILE',
                            value: this.broker.mobile_background[0],
                            image: true
                        },
                        {
                            key: 'PORTAL_BROKER_BACKGROUND_DESKTOP_LOGGED',
                            value: this.broker.desktop_background_logged[0],
                            image:  true
                        },
                        {
                            key: 'PORTAL_BROKER_BACKGROUND_MOBILE_LOGGED',
                            value: this.broker.mobile_background_logged[0],
                            image: true
                        },
                        {
                            key: 'PORTAL_BROKER_SENTENCE_LOGGED',
                            value: this.broker.sentence_logged,
                            image: true
                        },
                        // Technical
                        {
                            key: 'PORTAL_TECHNICAL_SENTENCE',
                            value: this.technical.sentence
                        },
                        {
                            key: 'PORTAL_TECHNICAL_COMPONENT_COLOR',
                            value: this.technical.component_color
                        },
                        {
                            key: 'PORTAL_TECHNICAL_BACKGROUND_COLOR',
                            value: this.technical.background_color
                        },
                        {
                            key: 'PORTAL_TECHNICAL_ACTIVE_REGISTER',
                            value: this.technical.active_register
                        },
                        {
                            key: 'PORTAL_TECHNICAL_BACKGROUND_DESKTOP',
                            value: this.technical.desktop_background[0],
                            image:  true
                        },
                        {
                            key: 'PORTAL_TECHNICAL_BACKGROUND_MOBILE',
                            value: this.technical.mobile_background[0],
                            image: true
                        },
                        // Settings
                        {
                            key: 'SETTINGS_DOMAIN',
                            value: this.settings.domain ? this.settings.domain.replace('http://', '').replace('https://', '').replace('/', '') : null
                        },
                        {
                            key: 'SETTINGS_DOMAIN_BROKER',
                            value: this.settings.domain ? this.settings.domain_broker.replace('http://', '').replace('https://', '').replace('/', '') : null
                        },
                        {
                            key: 'SETTINGS_API_BASE',
                            value: this.settings.api_base ? this.settings.api_base.replace('http://', '').replace('https://', '').replace('/', '') : null
                        },
                        {
                            key: 'SETTINGS_API_USER',
                            value: this.settings.api_user
                        },
                        {
                            key: 'SETTINGS_API_PASSWORD',
                            value: this.settings.api_password
                        },
                        {
                            key: 'SETTINGS_DISPLAY',
                            value: this.settings.display_name
                        },
                        {
                            key: 'SETTINGS_LOGO',
                            value: this.settings.logo[0],
                            image: true
                        },
                        {
                            key: 'SETTINGS_LOGO_WHITE',
                            value: this.settings.logo_white[0],
                            image: true
                        },
                        {
                            key: 'SETTINGS_LOGO_BLACK',
                            value: this.settings.logo_black[0],
                            image: true
                        },
                        {
                            key: 'SETTINGS_ICO',
                            value: this.settings.ico[0],
                            image: true
                        },
                    ]};

                     service
                    .update(data)
                    .then(resp => {
                        this.$bvToast.toast('Suas configurações foram salvas!', {
                            title: 'Configurações',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                         this.$bvToast.toast('Já existe outra empresa com esse domínio, por favor, selecione outro.', {
                            title: 'Configurações',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

                }
            });
        },
        fetchTheme(){
           
            let data = {
                domain: window.location.hostname.replace('app.', '')
            };

            service
            .search(data)
            .then(resp => {
                resp.forEach(element => {
                    switch(element.key){
                        case 'PORTAL_CLIENT_SENTENCE':
                            this.client.sentence = element.value;
                            break;
                        case 'PORTAL_CLIENT_COMPONENT_COLOR':
                            this.client.component_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_COLOR':
                            this.client.background_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.client.active_register = element.value;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_ENTERPRISE':
                            this.client.active_enterprise = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_DESKTOP':
                            this.client.desktop_background = [{path: element.value}];
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_MOBILE':
                            this.client.mobile_background = [{path: element.value}];
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_DESKTOP_LOGGED':
                            this.client.desktop_background_logged = [{path: element.value}];
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_MOBILE_LOGGED':
                            this.client.mobile_background_logged = [{path: element.value}];
                            break;
                        case 'PORTAL_CLIENT_SENTENCE_LOGGED':
                            this.client.sentence_logged = element.value;
                            break;
                        case 'PORTAL_BROKER_SENTENCE':
                            this.broker.sentence = element.value;
                            break;
                        case 'PORTAL_BROKER_COMPONENT_COLOR':
                            this.broker.component_color = element.value;
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_COLOR':
                            this.broker.background_color = element.value;
                            break;
                        case 'PORTAL_BROKER_ACTIVE_REGISTER':
                            this.broker.active_register = element.value;
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP':
                            this.broker.desktop_background = [{path: element.value}];
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE':
                            this.broker.mobile_background = [{path: element.value}];
                            break;

                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP_LOGGED':
                            this.broker.desktop_background_logged = [{path: element.value}];
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE_LOGGED':
                            this.broker.mobile_background_logged = [{path: element.value}];
                            break;
                        case 'PORTAL_BROKER_SENTENCE_LOGGED':
                            this.broker.sentence_logged = element.value;
                            break;                     
                        case 'PORTAL_TECHNICAL_SENTENCE':
                            this.technical.sentence = element.value;
                            break;
                        case 'PORTAL_TECHNICAL_COMPONENT_COLOR':
                            this.technical.component_color = element.value;
                            break;
                        case 'PORTAL_TECHNICAL_BACKGROUND_COLOR':
                            this.technical.background_color = element.value;
                            break;
                        case 'PORTAL_TECHNICAL_ACTIVE_REGISTER':
                            this.technical.active_register = element.value;
                            break;
                        case 'PORTAL_TECHNICAL_BACKGROUND_DESKTOP':
                            this.technical.desktop_background = [{path: element.value}];
                            break;
                        case 'PORTAL_TECHNICAL_BACKGROUND_MOBILE':
                            this.technical.mobile_background = [{path: element.value}];
                            break;
                        case 'SETTINGS_DOMAIN':
                            this.settings.domain = element.value;
                            break;
                        case 'SETTINGS_DOMAIN_BROKER': 
                            this.settings.domain_broker = element.value;
                            break;
                        case 'SETTINGS_API_BASE':
                            this.settings.api_base = element.value;
                            break;
                        case 'SETTINGS_API_USER':
                            this.settings.api_user = element.value;
                            break;
                        case 'SETTINGS_API_PASSWORD':
                            this.settings.api_password = element.value;
                            break;
                        case 'SETTINGS_DISPLAY':
                            this.settings.display_name = element.value;
                            break;
                        case 'SETTINGS_LOGO':
                            this.settings.logo = [{path: element.value}];
                            break;
                        case 'SETTINGS_LOGO_WHITE':
                            this.settings.logo_white = [{path: element.value}];
                            break;
                        case 'SETTINGS_LOGO_BLACK':
                            this.settings.logo_black = [{path: element.value}];
                            break;
                        case 'SETTINGS_ICO':
                            this.settings.ico = [{path: element.value}];
                            break;
                    }   
                });
                
            })
            .catch(err => {
            console.log(err)
            })
        },
    },
    mounted(){
        this.fetchTheme();
    }
}
</script>